import "foundation-sites";
(function($) {
    "use strict";
    $(function() {
        /** fix bug equalizer */
        $(document).foundation();

        function fixEqualizer() {
            const equalizerBlocks = $("[data-equalizer]");
            if (equalizerBlocks.length !== 0) {
                Foundation.reInit(equalizerBlocks);
            }
        }

        $(document).ready(function() {
            fixEqualizer();
        });
        $(window).resize(function() {
            fixEqualizer();
        });

    });
})(jQuery);