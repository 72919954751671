(function($) {

    $(function() {
        if (window.innerWidth <= 576) {
            setTimeout(toolbarMargin, 1000);

            function toolbarMargin() {

                let toolbar = $("#admin-toolbar");

                if (toolbar.length) {
                    let header = $("header");
                    let headerHeight = header.outerHeight();
                    let toolbarHeight = 62;
                    let resultHeight = headerHeight + toolbarHeight;
                    $("body").css({ "margin-top": resultHeight });
                    header.css({ "top": toolbarHeight });
                    $(".header__mobile_close").css({ top: toolbarHeight + 15 });

                }

                // 
            }

        }
    });


})(jQuery)