import "frontend-layout";
import "./helpers";

(function($) {
    // your code ...
    // 
    // Редактирование профиля
    let $edit_fileds_array = ["name", "phone", "position"];

    $("#profile-edit").on("click", function(e) {
        e.preventDefault();
        for (let i = 0; i < $edit_fileds_array.length; i++) {
            $("input[name='" + $edit_fileds_array[i] + "']").prop("disabled", false);
        }
        $("#profile-edit-submit").removeClass("hide");
    });

    // Поиск пользователей в профиле
    function search(q) {
        $.ajax({
            method: "POST",
            url: "/ajax_user/",
            data: {
                action: "ajax_user_search",
                data: {
                    q: q,
                },
            },
            success: function(response) {
                let data = $(response).filter("#users-list-table").html();
                $("#users-list-table").html(data);
            },
            error(xhr, status, error) {
                console.log(error);
            },
        });
    }

    $("#users-list-search").on("keyup", function(e) {
        e.preventDefault();
        let str = "";
        str = $.trim($(this).val());
        search(str);
    });

    $("#users-form-search").on("submit", function(e) {
        e.preventDefault();
        let str = "";
        str = $.trim($("#users-list-search").val());
        search(str);
    });

    // Скачивание отчетов
    $("a.report").on("click", function(e) {
        if ($(this).attr("download")) {
            return true;
        }
        e.preventDefault();

        let link = this;
        let attrs = this.attributes;

        let filter_attrs = {};
        if (attrs) {
            $.each(attrs, function(i, elem) {
                if (elem.name.indexOf("data") != -1) {
                    let name = elem.name.split("-")[1];
                    filter_attrs[name] = elem.value;
                }
            });
            $.ajax({
                method: "POST",
                url: "/do_export/",
                async: false,
                data: {
                    action: "ajax_user_export",
                    data: filter_attrs,
                },
                success: function(response) {
                    let res = JSON.parse(response);

                    if (res.success == true) {
                        link.setAttribute("href", res.file);
                        link.setAttribute("download", res.file_name);
                        link.click();
                    } else {
                        console.log("error  create file for downloads.");
                        console.log(res.message);
                    }
                },
                error(xhr, status, error) {
                    console.log(error);
                },
            });
        }
    });



    $(".table-show-more").on("click", function(e) {
        e.preventDefault();
        let table = $(this).data("table");
        $("#" + table).show();
        $(this).detach();
    });

    $(".table__hidden").each(function(elem) {
        $(this).css({ display: "block" });
        let table = $(this).find("table");
        let head = table.find("thead");
        let theadHeight = head.outerHeight();
        if (head.hasClass("hidden")) {
            $(table).css({ margin: "-" + theadHeight + "px 0 0 0" });
            $(this).css({ 'margin-bottom': '55px', display: "none" });
        } else {
            $(this).css({ display: "none" });
            $(this).css({ 'margin-bottom': '55px', display: "none" });
        }

    });
})(jQuery);
//