import "@fancyapps/fancybox";
import "@fortawesome/fontawesome-free/js/all.js";
import "./foundation.js";
import "./fontLoad.js";
// import "./swiper.min.js";
import {
    Swiper,
    Navigation,
    Pagination,
    Scrollbar,
    EffectFade,
    Autoplay
} from 'swiper';

Swiper.use([Navigation, Pagination, Scrollbar, EffectFade, Autoplay]);

import "../scss/style.scss";

(function($) {
    "use strict";
    $(function() {

        // HAMBURGER
        $(".header__mobile_open button").click(function() {
            $(".header__block").addClass("header__block_active");
            $("body").addClass("header__block-opener");
        });
        $(".header__mobile_close button").click(function() {
            $(".header__block").removeClass("header__block_active");
            $("body").removeClass("header__block-opener");
        });

        // TOP MENU 

        $(".header__menu-inner__item").on("mouseenter", function() {
            let win = $(window).width();
            let rightMnu = $(this).find(".header__menu-inner-right");
            if (rightMnu.length) {


                let left = rightMnu.offset().left;
                let width = rightMnu.outerWidth(true);
                let innerWidth = $(this).closest(".header__menu-inner").outerWidth(true);
                let offset = win - (left + width);
                if (offset < 0) {
                    rightMnu.css({ right: innerWidth, left: "auto" });
                } else {
                    rightMnu.css({ right: "auto", left: innerWidth });
                }
            }

        });


        // SLIDER SWIPER 

        const swiper = new Swiper(".slider-regular .swiper-container", {
            /* cssMode: true, */
            loop: true,
            effect: "fade",
            roundLengths: true,
            navigation: {
                nextEl: ".slider-regular .swiper-button-next",
                prevEl: ".slider-regular .swiper-button-prev",
            },
            pagination: {
                el: ".slider-regular .swiper-pagination",
            },
            autoplay: {
                delay: 5000,
            },
            disableOnInteraction: false,
            clickable: true,
            keyboard: true,
        });


    });

})(jQuery);